html {
  font-size: 62.5%; /* 62.5% of 16px = 10px */
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  overflow: auto;
}

html,
body,
html * {
  font-family: 'Roboto', sans-serif;
}
