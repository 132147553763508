.csvDownloader {
  color: #0772e5;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
  margin-top: 16px;
  cursor: pointer;
}
